<template>
  <!-- details -->
        <div class="blog-area pd-top-100">
            <div class="container">
                <!-- article -->
                <div class="article">
                    <div class="row" >
                        <div class="col-12 col-xl-9" v-for="publish in video" :key="publish.id">
                            <div class="row">
                                <div class="col-12" >
                                    <video  class="plyr" autoplay style="margin-right: 0px;width: 100%;" controls="" controlsList="nodownload">
                                        <source :src="publish.vidoeUrl" type="Video/mp4" size="720">
                                    </video>
                                    
                                </div>
                            </div>
                            <div class="row" style=" padding: 0px 5px; margin-bottom: 8px;">
                                <div class="col-12">
                                    <div class="comments__item">
										<div class="comments__autor">
												<!-- <img class="comments__avatar" src="assets/img/avatar.svg" style="width: 50px;height: 50px;" alt=""> -->
											<span class="comments__name arabicKufi" style="color: #046162;margin-right: 5px;"><h4>{{ publish.name }} - {{ publish.createDate.substring(0,4) }}</h4></span>
											<!-- <span class="comments__time" style="color: #046162;margin-right: 5px;" ><h6></h6></span> -->
										</div>
									</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-xl-3" style="margin-top: 30px;">
                            <div class="sidebar sidebar--mt" style="margin-top: 2px;">
                                <div class="row row--grid">
                                    <div class="col-12" v-for="publish in cat" :key="publish.id">
                                        <div class="row" style="background: rgba(131, 137, 159, 0.07) none repeat scroll 0% 0%; padding: 0px 5px; margin-bottom: 8px;">
                                            <div class="col-6 col-sm-4 col-md-3 col-xl-6" style="padding-right: 0px;"> 
                                                <div class="card" style="background-image: linear-gradient(0deg, rgba(0, 66, 105, 0) 0px, rgba(113, 198, 247, 0)); border-radius: 0px; box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px, rgba(0, 0, 0, 0) 0px 15px 20px; margin-top: 0px;height: auto;width: 145px;display: flex;position: relative;">
                                                    <a @click="getFeed(publish)" class="card__cover pointer" style="height: 12vh;">
                                                        <img v-lazy="publish.cover" alt="">
                                                        <svgVideos />
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col-6 col-sm-4 col-md-3 col-xl-6" style="text-align: right;">
                                                <a @click="getFeed(publish)">
                                                    <ul class="arabicKufi pointer">
                                                        <li><h5 class="card__title" style="color: rgb(4, 97, 98); margin-top: 13px;">{{ publish.name }}</h5></li>
                                                        <li><h6 style="color: #046162;"></h6>{{ publish.createDate.substring(0,10) }}</li>
                                                        <!-- <li ><h6  style="color: #046162;"></h6></li>
                                                        <li style="color: #046162;"></li> -->
                                                    </ul>   
                                                </a>                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>  
                    </div>
                </div>
            </div>
        </div>
        <br><br>
</template>

<script>
import { HTTP } from '@/Api/http-common';
import { ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useCookie } from 'vue-cookie-next';
import svgVideos from "@/components/svgData/svgVideos.vue";
export default {
   name: 'Contents',
   props: {
       id: String,
    },
    components: { 
        svgVideos
    },
    async setup(props) {
         const router = useRouter();
         const route = useRoute();
         const cookie = useCookie()

        const video = ref([]);
        const cat = ref([]);
        try {
          await HTTP.get("ContentById.php?id=" + props.id).then((res) => {
               video.value = res.data.readPost;
               })
        } catch (err) {
            console.log(err)
        }
        try {
          await HTTP.get("CatogeryById.php?Cat_id=" + cookie.getCookie("cat_id") + "&LIMIT=8").then((res) => {
               cat.value = res.data.readPost;
               })
        } catch (err) {
            console.log(err)
        }
        const getFeed = (publish) => {
            router.push({ name: "Content", params: { id: publish.id } });
        };
   
    return {
        video,
        cat,
        getFeed
    }
    },
}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
.pd-top-100 {
  padding-top: 50px;
}
.plyr {
  font-family: 'Zain_ExtraBold', sans-serif;
  font-weight: 400;
  border-radius: 16px;
  margin-top: 30px;
}
.comments__item {
  margin-bottom: 20px;
  display: block;
  background-color: #d1def736;
  padding: 10px;
  border-radius: 16px;
}
.comments__name {
  display: flex;
  font-size: 16px;
  color: #fff;
  line-height: 20px;
  font-weight: 500;
  text-align: match-parent;
  margin-right: 75px;
}
.card__cover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  border-radius: 3px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  padding: 0px;
}
.card:hover .card__cover::before {
  opacity: 0.7;
}
.card__cover::before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.8) 75%, rgba(0, 0, 0, 0.9) 100%);
    opacity: 0.1;
    transition: 0.5s;
}
.card__cover img {
  width: 100%;
position: relative;
z-index: 1;
transition: 0.5s;
height: 100%;
}
.card__cover svg {
  width: 56px;
}
.card__cover svg {
  position: absolute;
  display: block;
  stroke: #fff;
  width: 46px;
  height: auto;
  transition: 0.5s;
  z-index: 3;
  transform: scale(0.8);
  opacity: 0;
}
.card:hover .card__cover svg {
  opacity: 1;
  transform: scale(1);
}
ul {
  margin: 0;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
  padding: 0;
  list-style: none;
}
</style>